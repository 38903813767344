const elements = document.getElementsByClassName( "main-header" );
const sticky = elements[0];
const limit = 400;
let scrollPosition = 0;
let ticking = false;
console.log(sticky);
window.addEventListener('scroll', (e) => {
  scrollPosition = window.scrollY;
  if(!ticking) {
    window.requestAnimationFrame(() => {
      if (limit < scrollPosition) {
        sticky.classList.add('sticky-on');
      } else {
        sticky.classList.remove('sticky-on');
      }
      ticking = false;
    });
  }
  ticking = true;
});
