$(function(){
  $('.js-hero-slider').slick({
    dots: false,
    arrows: false,
    infinite: true,
    fade: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 12000,
  });
});