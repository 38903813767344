let animationTargets = document.getElementsByClassName( "js-target-section" );
animationTargets = [].slice.call(animationTargets);
const limit = 400;
let pageScroll;
let windowHeight = window.innerHeight;

window.addEventListener('scroll', (e) => {
  pageScroll = window.pageYOffset;
  animationTargets.map((target) => {
    console.log(target + target.getBoundingClientRect().top);
    const targetPosition = target.getBoundingClientRect();
    if (pageScroll > targetPosition.top + limit) {
      target.classList.add('animated-on');
    }
  });
});
