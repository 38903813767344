const menuToggle = document.getElementById("menu-toggle");
const wholeDocument = document.body;
const target = document.getElementById("header-toggle-nav");
const headerNavItems = document.getElementsByClassName( "js-header-nav-item" );

let toggleOpen = false;


menuToggle.addEventListener('click', (e) => {
  if (!toggleOpen) {
    target.classList.add('header-open');
    menuToggle.classList.add('toggle-on');
    toggleOpen = true;
  } else {
    target.classList.remove('header-open');
    menuToggle.classList.remove('toggle-on');
    toggleOpen = false;
  }
});

target.addEventListener('click', (e) => {
  if (!toggleOpen) {
    target.classList.add('header-open');
    menuToggle.classList.add('toggle-on');
    wholeDocument.style.overflow = "hidden";
    toggleOpen = true;
  } else {
    target.classList.remove('header-open');
    menuToggle.classList.remove('toggle-on');
    wholeDocument.style.overflow = "visible";
    toggleOpen = false;
  }
});
